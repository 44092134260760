.appContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: var(--tg-safe-area-top, 0);
    padding-bottom: var(--tg-safe-area-bottom, 0);
}

.content {
    flex-grow: 1;
    overflow-y: auto;
}

.content {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 5em;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}
