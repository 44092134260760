body, html {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: scroll;
    background: white;
}

body::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

root {
    --safe-area-inset-bottom: calc(100vh - var(--tg-viewport-stable-height, 100vh))
}
